<template>
  <div class="modal">
    <div class="backdrop" @click="close"/>
    <div class="container flex justify-center items-center ctlModal">
      <div class="content ctlModal2">
        <p class="close" @click="close">
          <i class="fas fa-times"></i>
        </p>
        <div class="content_modal ctlModal3">
          <p class="title">Alguma dúvida?</p>
          <p class="subtitle">Fale com a gente agora mesmo e entraremos em contato logo logo ;)</p>
          <v-input name="assunto" v-model="form.assunto" :error="errors.first('assunto')" label="Assunto*" v-validate="'required|min:2'"></v-input>
          <v-input type="textarea" class="w-full" name="mensagem" v-model="form.mensagem" label="Mensagem*" :error="errors.first('mensagem')" v-validate="'required|min:10'"></v-input>
          <div class="buttons">
            <v-button class="hidden lg:w-1/2 lg:block" @click="close" color="error" :loading="loading">Cancelar</v-button>
            <v-button class="lg:w-1/2" @click="submit" :loading="loading">Enviar</v-button>
            <v-button class="lg:w-1/2 lg:hidden" @click="close" color="error" :loading="loading">Cancelar</v-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/Buttons/Button'
import VInput from '@/components/Inputs/Input'

import Contact from '@/services/Investor/Contact/Contact'

export default {
  components: { VButton, VInput },
  data () {
    return {
      loading: false,
      form: {
        nome: '',
        email: '',
        mensagem: ''
      }
    }
  },
  methods: {
    close () {
          this.$emit('closeModal')
          this.$validator.reset()
            this.form = {
                  assunto: '',
                  mensagem: ''
             }
    },
    async submit () {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            await Contact.sendContact(this.form);
            this.$store.dispatch('notification', { type: 'success', message: 'Seu contato foi enviado com sucesso! Responderemos em breve via e-mail.' })
            this.close()
          } catch (error) {
            this.$store.dispatch('notification', { type: 'error', message: 'Houve algum erro. Tente novamente!' })
            if (error.response && error.response.data && error.response.data.erros) {
              Object.entries(error.response.data.erros).forEach(([campo, erro]) => this.errors.add({ field: campo, msg: erro }))
            }
          } finally {
            this.loading = false
          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>


.modal
  @apply fixed flex items-center justify-center  w-screen h-screen top-0 left-0
  z-index: 99

.ctlModal
  position: absolute
  padding: 0
  @screen lg
    margin-top: 0

.ctlModal > div
    padding: 0
    @screen md
      @apply p-4

.ctlModal2
  height: 100vh
  @screen lg
    height: auto

.ctlModal3
  @apply p-10
  height: 100%
  @screen lg
    height: auto

.backdrop
  @apply fixed w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)


.content
  @apply relative w-full bg-white z-50 flex flex-col justify-center pb-6 pt-8 rounded-lg
  @screen lg
    @apply w-1/2

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.content_modal
  @apply flex flex-col w-full px-4
  @screen lg
    @apply px-8

.buttons
  @apply flex flex-col items-center
  @screen lg
    @apply flex-row

.title
  @apply font-bold text-lg text-black text-center uppercase text-primary mb-2
  @screen lg
    @apply text-xl

.subtitle
  @apply font-medium text-sm text-black mb-3 text-center
  @screen lg
    @apply text-base text-left

</style>
