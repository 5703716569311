const money = value => {
  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  return formatter.format(value)
}

export default {
  methods: {
    formatMoney: money
  },
  filters: {
    money
  }
}
