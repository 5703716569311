<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <p class="title">Conta bancária não verificada</p>
        <p class="subtitle my-5">Vá ao seu perfil e cadastre sua conta bancária! <br> Caso já tenha cadastrado, aguarde a análise em até 1 dia útil.</p>
        <v-button class="flex justify-center mb-2 lg:mb-0" size="sm" color="error" @click="$emit('closeModal')">Voltar</v-button>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/Buttons/Button'

export default {
  name: 'bank-account-not-approved-modal',
  components: { VButton }
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center w-screen h-screen top-0 left-0 z-50

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full bg-white z-50 flex flex-col justify-center rounded-lg pt-4 px-4
  @screen lg
    @apply w-3/4

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.title
  @apply text-secondary font-bold mb-2
  @screen lg
    @apply text-lg

.subtitle
  @apply text-xs opacity-75 text-center
  @screen lg
    @apply text-sm

</style>
