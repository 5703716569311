<template>
  <div class="navbar">
    <contact-modal v-show="contactModal" @closeModal="contactModal = false"/>
    <transition name="fade">
      <modal-nav v-show="modalnav" @closeModal="modalnav = false">
        <div slot="body" class="flex flex-col items-center mt-4">
          <img class="avatar" :src="$store.state.investor.avatar">
            <div class="badge" v-show="unreadMessages > 0" @click="$router.push('/investidor/mensagens')">{{unreadMessages}}</div>
          <p class="font-bold my-2 text-sm uppercase">{{name}}</p>
          <p class="user_money">{{$store.state.investor.balance | money}}</p>
         <!-- <div class="flex justify-center items-center mt-4">
            <div :class="`money_bag_red ${contaIuguAtiva ? '' : 'money_bag_disabled'}`" @click="modalWithdrawal = true"/>
            <div :class="`money_bag_green ${contaIuguAtiva ? '' : 'money_bag_disabled'}`" @click="modalDeposit = true"/>
          </div> -->
          <hr class="h-px w-full bg-black opacity-25 mt-4">
          <p class="link_dashboard" @click="modalnav = false; $router.push('/investidor/painel')">Início</p>
          <p class="link_dashboard" @click="modalnav = false; $router.push('/investidor/carteira')">Carteira</p>
          <p class="link_dashboard" @click="modalnav = false; $router.push('/investidor/meus-investimentos')">Meus Investimentos</p>
          <p class="link_dashboard" @click="modalnav = false; $router.push('/investidor/mensagens')">Mensagens</p>
          <p class="link_dashboard" @click="modalnav = false; $router.push('/investidor/relatorios')">Relatórios</p>
          <p class="link_dashboard" @click="modalnav = false; $router.push('/investidor/perfil')">Perfil</p>
          <p class="link_dashboard" @click="modalnav = false;  contactModal = true">Fale Conosco</p>

          <p class="link_dashboard" @click="$emit('logout')">Sair</p>
        </div>
      </modal-nav>
    </transition>
    <withdrawal-modal v-if="contaIuguAtiva && modalWithdrawal" @closeModal="modalWithdrawal = false"/>
    <deposit-modal v-if="contaIuguAtiva && modalDeposit" @closeModal="modalDeposit = false"/>
    <bank-account-not-approved-modal v-if="!contaIuguAtiva && (modalDeposit || modalWithdrawal)" @closeModal="modalDeposit = false; modalWithdrawal = false"/>
    <header>
      <router-link class="logo" to="/investidor/painel"></router-link>
      <img @click="modalnav = true" class="bars" src="@/assets/images/menu.svg" alt="">
      <nav>
        <div class="flex justify-center items-center">
           <div class="relative">
            <router-link to="/investidor/perfil" class="rounded-full">
              <img class="avatar" :src="$store.state.investor.avatar">
            </router-link>
            <router-link class="badge" v-show="unreadMessages > 0" to="/investidor/mensagens">{{unreadMessages}}</router-link>
          </div>
          <div>
            <p class="username">{{$store.state.investor.name}}</p>
            <p class="user_money">{{$store.state.investor.balance | money}}</p>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>

import BankAccountNotApprovedModal from '@/components/Investor/Modal/BankAccountNotApprovedModal'
import ModalNav from '@/components/Modal/ModalNav'
import ContactModal from '@/components/Modal/ContactInvestorModal'

import MoneyFilter from '@/mixins/moneyFilter'

export default {
  name: 'investor-navbar',
  components: {BankAccountNotApprovedModal, ModalNav, ContactModal },
  mixins: [MoneyFilter],
  props: ['unreadMessages', 'contaIuguAtiva'],
  data () {
    return {
      contactModal :false,
      modalnav: false,
      modalWithdrawal: false,
      modalDeposit: false,
            loadCount: 0,
      nameInitial: null
    }
  },
    computed: {
    name(){
     return this.$store.state.investor.name
    }
  },
  watch: {
    name(){
      if(!this.nameInitial && this.name != this.nameInitial){
        this.nameInitial = ''+this.name;
      }
      console.log(`${this.loadCount} : ${this.nameInitial} : ${this.name}`);

      if(this.name){
        if(this.loadCount > 0 && this.nameInitial != this.name){
          alert(`Você fez o login na conta de ${this.name} em outra aba, saindo da conta de ${this.nameInitial}`)
          location.reload();
        }
        this.loadCount++;
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.navbar
  @apply w-full py-2 flex justify-center z-40
  @screen md
  @apply
    @apply items-center

header
  @apply h-16 w-full flex flex-row items-center justify-between
  @screen md
    @apply h-16 w-full flex flex-row items-center justify-between
  @screen lg
    @apply h-16 w-full flex flex-row items-center justify-between

.logo
  @apply bg-no-repeat bg-center h-10 w-32 cursor-pointer bg-contain
  background-image: url("../../../assets/images/logo.svg")
  @screen lg
    @apply h-12 w-40

nav
  @apply hidden
  @screen md
    @apply block flex items-center
  @screen lg
    @apply block flex items-center

.money_bag_disabled
  @apply opacity-25

.avatar
  @apply h-14 w-14 shadow rounded-full object-cover
  @screen md
    @apply mr-4

.badge
  @apply flex items-center justify-center bg-red-600 rounded-full text-xs text-white h-4 w-4 absolute bottom-0 right-0 shadow

.username
  @apply font-semibold text-sm text-black opacity-75 uppercase

.user_money
  @apply text-xs text-primary text-right

.bars
  @apply block w-6 h-6
  @screen md
    @apply hidden
  @screen lg
    @apply hidden
</style>
