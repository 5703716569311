<template>
  <div class="main">
    <div class="w-full flex flex-col px-6 justify-center bg-white shadow-md">
      <nav-bar ref="navbar" :unreadMessages="unreadMessages" :contaIuguAtiva="contaIuguAtiva" @logout="logout" />
    </div>
    <div class="flex flex-row">
      <side-bar class="sidebar" @logout="logout" />
      <div class="container">
        <transition name="fade">
          <router-view @updateMessages="getUnreadMessages"></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/Investor/NavBar/NavBar'
import SideBar from '@/components/Investor/SideBar/SideBar'
import Dashboard from '@/services/Investor/Dashboard'
import { EventBus } from '@/event-bus.js';

export default {
  name: 'investor-dashboard',
  components: { NavBar, SideBar },
  data() {
    return {
      unreadMessages: 0,
      contaIuguAtiva: false
    }
  },
  created() {
    EventBus.$on('novaTransacaoWallet', this.getBalance);
  },
  beforeDestroy() {
    EventBus.$off('novaTransacaoWallet', this.getBalance);
  },
  methods: {
    async getUnreadMessages() {
      try {
        this.unreadMessages = (await Dashboard.getUnreadMessages()).data.data
      } catch (error) { }
    },
    logout() {
      clearInterval(this.interval)
      this.$store.dispatch('setLogout')
      this.$router.push('/')
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    clearJivoInterval() {
      clearInterval(this.jivoInterval)
    },
    async getBalance() {
      console.log('getBalance')
      try {
        this.$store.dispatch(
          'setBalance',
          (await Dashboard.getBalance()).data.data
        )

      } catch (error) {
        console.error(error)
      }
    }
  },
  async mounted() {
    try {
      this.$store.dispatch('initSignalR');
      const response = (await Dashboard.getStatus()).data.data
      this.contaIuguAtiva = response.contaIuguAtiva
      this.$store.dispatch('setInvestorName', response.nome)
      this.$store.dispatch('setContaIuguAtiva', response.contaIuguAtiva)
    } catch (error) {
      console.error(error)
    }
    try {
      const avatar = await (await Dashboard.getAvatar().data);
      this.$store.dispatch('setAvatar', avatar ? this.getBase64(avatar) : null)
    } catch (error) { }

    this.getUnreadMessages()

    await this.getBalance();
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="sass" scoped>

.main
  @apply w-full min-h-screen max-h-full
  background: #F5F5F5

.sidebar
  @apply hidden
  @screen md
    @apply block

.container
  padding-right: 1rem !important
  padding-left: 1rem !important
  @screen md
    padding-right: 2rem !important
    padding-left: 2rem !important
</style>
