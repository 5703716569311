import api from '@/plugins/axios'

const base = 'v1/investidor/'

export default {
    generateExcel() {
        return api.get(`${base}gerar-planilha-fluxo`, { responseType: 'blob' });
    },
    getStatus() {
        return api.get(`${base}status-cadastro`)
    },
    getVisaoGeral() {
        return api.get(`${base}visao-geral`)
    },
    getBalance() {
        return api.get(`${base}wallet/saldo`)
    },
    getNextInvestimentRound() {
        return api.get(`${base}data-proxima-rodada`)
    },
    getOpportunities(page, filter) {
        return api.get(`${base}oportunidades`, { params: { pagina: page, categoria: filter } })
    },
    getOpportunitiesOpen(page, filter) {
        return api.get(`${base}oportunidades/abertas`, { params: { pagina: page, categoria: filter } })
    },
    getOpportunitiesClosed(page, filter) {
        return api.get(`${base}oportunidades/encerradas`, { params: { pagina: page, categoria: filter } })
    },
    getOpportunity(id) {
        return api.get(`${base}oportunidade/${id}`)
    },
    getUnreadMessages() {
        return api.get(`${base}central-mensagens/count-nao-lidas`)
    },
    getTIRInformations() {
        return api.get(`${base}estatisticas-tir`)
    },
    getNonPaymentRatings() {
        return api.get(`${base}estatisticas-inadimplencia-rating`)
    },
    getAvatar() {
        return api.get(`${base}avatar`, { responseType: 'blob' })
    },
    registerInterest(data) {
        return api.post(`${base}registrar-interesse`, data)
    },
    getInvoice(ofertaId) {
        return api.get(`${base}oportunidade/${ofertaId}/fatura`)
    },
    getInvoiceUrl(ofertaId) {
        return api.post(`${base}oportunidade/${ofertaId}/link-fatura`)
    }
}