<template>
  <div class="sidebar">
    <contact-modal v-show="contactModal" @closeModal="contactModal = false"/>
    <router-link v-for="(item,index) in items" :key="index" :to="item.to" class="sidebar_item">
      <img :src="item.uri" alt="inicio">
      <span class="tooltip">{{ item.legend }}</span>
    </router-link>
     <a href="javascript://" class="sidebar_item">
      <img :src="require('@/assets/images/investor_panel/fale-conosco-4.svg')" v-on:click="contactModal = true" alt="inicio">
      <span class="tooltip">Fale Conosco</span>
    </a>
    <p class="sidebar_item" @click="$emit('logout')">
      <img :src="require('@/assets/images/investor_panel/logout.svg')" alt="inicio">
      <span class="tooltip">Sair</span>
    </p>
  </div>
</template>

<script>
import ContactModal from '@/components/Modal/ContactInvestorModal'

export default {
  name: 'investor-sidebar',
  components: {ContactModal},
  watch: {
    contactModal(){
      if(this.contactModal){
        document.body.classList.add("modal-open");
      } else{
        document.body.classList.remove("modal-open");
      }
    }
  },
  data () {
    return {
      contactModal : false,
      items: [
        {
          to: '/investidor/painel',
          legend: 'Dashboard',
          uri: require('@/assets/images/investor_panel/layout.svg')
        },
        {
          to: '/investidor/carteira',
          legend: 'Carteira',
          uri: require('@/assets/images/investor_panel/deposit.svg')
        },
        {
          to: '/investidor/meus-investimentos',
          legend: 'Meus investimentos',
          uri: require('@/assets/images/investor_panel/analytics.svg')
        },
        {
          to: '/investidor/mensagens',
          legend: 'Mensagens',
          uri: require('@/assets/images/investor_panel/chat.svg')
        },
        {
          to: '/investidor/relatorios',
          legend: 'Relatórios',
          uri: require('@/assets/images/investor_panel/charts.svg')
        },
        {
          to: '/investidor/perfil',
          legend: 'Perfil',
          uri: require('@/assets/images/investor_panel/clipboard.svg')
        }
      ]
    }
  },
  methods: {
    showModal(){
      this.contactModal = true;
    }
  }
}
</script>

<style lang="sass" scoped>
.sidebar
  @apply h-full w-20 shadow-md z-10

.sidebar_item
  @apply relative flex flex-col items-center justify-center w-full h-20 bg-white border border-gray-400 cursor-pointer
  &.router-link-exact-active
    @apply bg-secondary border-none
    &>img
      filter: brightness(0) invert(1)
  &:hover
    @apply bg-secondary border-secondary
    opacity: 0.85
    &>img
      filter: brightness(0) invert(1)
    &>.tooltip
      visibility: visible
  &>.tooltip
    @apply absolute invisible bg-secondary text-white text-center text-xss font-semibold rounded-sm whitespace-no-wrap py-1 px-4 z-10
    left: 110%
  &>.tooltip::after
    @apply absolute border-transparent
    content: ""
    top: 50%
    right: 100%
    margin-top: -5px
    border-width: 5px
    border-style: solid
    border-right-color: #5316CD
</style>
